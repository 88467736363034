.Room_videos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Room_single-video-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Room_single-video-container-self {
  background-color: lightgreen;
}

.Room_mute-button {
  width: 150px;
  margin-top: 10px;
}
